export const en = {
  poll_thanks: "Thank you for your feedback.",
  poll_thanks_2: "We hope you continue to have a nice evening.",
  poll_thanks_desc: "Yours Volkswagen",
  question_1: "How did you like the Brand Night? ",
  question_2: "How did you like the dinner?",
  question_3: "Overall, how did you like the Brand Experience 2023?",
  about_page: "This is an about page",
  footer: "Copyright 2023",
};
